<template>
  <bg-image-wrapper
    src="bg-for-carrier.jpeg"
    title="ПЕРЕВОЗЧИКИ, ЭТО СЕРВИС ДЛЯ ВАС"
    flip
  >
    <section slot="body" class="for-carriers fw-l t-black-light">
      <p>
        Если у вас есть действущий договор с нашими заказчиками, вам достаточно
        просто зарегистрироваться в сервисе и указать его номер. После быстрой
        проверки вы будете допущены к торгам.
      </p>
      <p>
        Если вы только хотите начать сотрудничество заполните форму ниже, и мы
        передадим инорфмацию и поможем в заключении договора.
      </p>
    </section>
    <bid-form title="ЗАПОЛНИТЕ ЗАЯВКУ НА ЗАКЛЮЧЕНИЕ ДОГОВОРА" carrier />
  </bg-image-wrapper>
</template>

<script>
import BgImageWrapper from '@/components/Wrappers/BgImageWrapper'
import BidForm from '@/components/TablesFormsFilters/BidForm'

export default {
  name: 'ForCarriers',
  components: { BgImageWrapper, BidForm }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.for-carriers
  font-size: 18px
  line-height: 24px
  @include toH(700px)
    font-size: 16px
    line-height: 20px
</style>
